import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { forkJoin, last, takeUntil } from 'rxjs';
import { ImageFileRepositoryService } from 'src/app/shared/components/image-file-repository/services/image-file-repository.service';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import { ServerErrorMessage } from 'src/app/util/common.constants';
import { cleanFileName, getFileExtension } from 'src/app/util/common.util';
import { ImageCompress } from 'src/app/util/image-compress';
import { ReportResponseModel } from '../../model/report.model';
import { ReportFormService } from '../../services/report-form.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-form-view',
  templateUrl: './report-form-view.component.html',
  styleUrls: ['./report-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFormViewComponent extends TakeUntilDestroy implements OnInit {

  form: FormGroup;
  formControls: any;
  isLoading: boolean;
  title: string;
  assetId: string;
  companyId: string;
  locationId: string;
  assetFullPath: string;
  locationName: string;
  errorMessage:string;
  files: any = [];

  constructor(
    private route: ActivatedRoute,
    private formService: ReportFormService,
    private reportService: ReportService,
    private cdr: ChangeDetectorRef,
    private messageService: NzNotificationService,
    private fileService: ImageFileRepositoryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formService.buildReportForm();
    this.formControls = this.formService.getFormControls(this.form);
    this.title = 'Report Issue';
    this.assetFullPath = '';
    this.assetId = this.route.snapshot.params['assetId']
    this.getAssetDetails(this.assetId);
  }

  onSubmit(images: any) {
    this.onReportSubmit(images);
  }

  async onReportSubmit(images: any) {
    this.isLoading = true;
    const data = this.form.getRawValue();
    data.assetId = this.assetId;
    data.companyId = this.companyId;
    data.locationId = this.locationId;
    await this.uploadFiles(images, data.email);
    if (images.length && images.length !== this.files.length) {
      this.messageService.create('error', 'Error', 'Unable to upload files, please try again', { nzClass: 'error' });
      this.isLoading = false;
      this.cdr.markForCheck();
      return;
    }
    if (this.files && this.files.length > 0) {
      data.files = this.files;
    }
    this.reportService.report(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: ReportResponseModel) => {
          this.isLoading = false;
          if (data.success) {
            this.messageService.create('success', 'Success', 'Report submitted successfully', { nzClass: 'success' });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.messageService.create('error', 'Error', ServerErrorMessage, { nzClass: 'error' });
          }
          this.cdr.markForCheck();
        },
        error: (e) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  getAssetDetails(assetId: string) {
    this.isLoading = true;
    this.reportService.getAssetDetails(assetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: ReportResponseModel) => {
          this.isLoading = false;
          if (data.success && data.data) {
            // this.assetName = data.data.assetName;
            this.companyId = data.data.companyId;
            this.locationId = data.data.locationId;
            this.locationName = data.data.locationName;
            this.getAssetFullPath(data.data);
          } else {
            console.log('Error in asset details', data.message);
            this.errorMessage = 'No Asset';
          }
          this.cdr.markForCheck();
        },
        error: (e) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  getAssetFullPath(assetData: any) {
    let fullPath = '';
    const { assetName, assetLevel, paName, rootName } = assetData;
    if (assetLevel !== 'asset') {
      fullPath = `${rootName ? rootName + ' > ' : ''} ${paName ? paName + ' > ' : ''}`;
    }
    this.assetFullPath =  fullPath + assetName;
  }

  async uploadFiles(files: any, email: string) {
    const filePath = `/${this.companyId}/${this.locationId}/thumb`;
    for (const file of files) {
      let fileObj;
      const fileExt = getFileExtension(file.name) || '';
      // const fileName = `${Math.floor(Math.random() * 100000)}_${moment.now()}.${fileExt}`;
      const fileName = cleanFileName(file.name, fileExt);
      if (file.type.includes('image') && !file.type.includes('image/gif') && !file.type.includes('svg')) {
        let compressedFile: any;
        if (file.size > 102400) {
          const data = await ImageCompress.compress(file, this.getPercentage(file.size, file.type));
          compressedFile = ImageCompress.base64ToFile(data.replace(/^data:image\/\w+;base64,/, ""), file.type, fileName);
        } else {
          compressedFile = file;
        }
        const tData = await ImageCompress.compress(compressedFile, 50);
        const thumbFile = ImageCompress.base64ToFile(tData.replace(/^data:image\/\w+;base64,/, ""), file.type, fileName);
        fileObj = { compressed: compressedFile, thumb: thumbFile };
        // images.push(compressedFile);
      } else {
        // images.push(file);
        fileObj = { compressed: file, thumb: file };
      }
      const uploadedFiles = await this.uploadFile(fileObj, email);
      // console.log('IUploaded files', uploadedFiles)
      if (uploadedFiles && uploadedFiles[0].file) {
        const uploadedFile = uploadedFiles[0].file;
        this.files = [{
          fileId: uploadedFile.fileId,
          fileName: uploadedFile.fileName,
          filePath: `${filePath}/${uploadedFile.fileName}`,
          fileSize: fileObj.thumb.size,
          fileType: fileObj.thumb.type
        }, ...this.files]
      } else {
        this.files = [{
          fileId: '',
          fileName: file.fileName,
          filePath: `${filePath}/${file.fileName}`,
          fileSize: fileObj.thumb.size,
          fileType: fileObj.thumb.type
        }, ...this.files]
      }
    }
    // console.log('uploaded files are ', this.files)
  }

  async uploadFile({ compressed, thumb }: { compressed: File, thumb: File }, email: string) {
    const fileExt = getFileExtension(compressed.name) || '';
    // const fileName = `${compressed.name.substring(0, compressed.name.length - (fileExt.length + 1))}_${moment.now()}.${fileExt}`;
    // const fileName = `${Math.floor(Math.random() * 100000)}_${moment.now()}.${fileExt}`;
    const fileName = cleanFileName(compressed.name, fileExt);
    const filesArray: any[] = [];
    filesArray.push(this.fileService.uploadToServerPublic(compressed, fileExt, this.companyId, this.locationId, fileName, false, email));
    if (!compressed.type.includes('video') && !compressed.type.includes('application') && !compressed.type.includes('image/gif') && !compressed.type.includes('svg')) {
      filesArray.push(this.fileService.uploadToServerPublic(thumb, fileExt, this.companyId, this.locationId, fileName, true, email));
    }
    this.isLoading = true;
    return forkJoin(filesArray)
    .pipe(last())
    .toPromise();
  }

  getPercentage(fileSize: number, fileType: string) {
    const percentage = fileType.includes('png') ? 50 : 80;
    return Math.ceil(((1024/fileSize)*1024)*percentage);
  }

}
