<div class="flex justify-center">
  <form nz-form [formGroup]="form" class="signup-form">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.email">
        <input nzSize="large" nz-input [formControl]="formControls.email" placeholder="Email *"
          class="font-semibold text-center h-custom-input text-15" />
      </nz-form-control>
      <div class="relative flex items-center">
        <span nzTooltipTitle="Go To Login" nzTooltipPlacement="right" nz-tooltip
          class="text-3xl cursor-pointer material-symbols-rounded absolute leading-none ml-2"
          (click)="onGotoLogin()">{{cancelIcon}}</span>
      </div>
    </nz-form-item>
  </form>
</div>
<div class="flex justify-center">
  <nz-spin nzSimple [nzSpinning]="isSubmitting" nzTip="Please wait..."></nz-spin>
  <button *ngIf="!isSubmitting" nz-button class="bg-inherit border-0" [disabled]="!form.valid" (click)="onSubmitForm()">
    <span class="text-6xl cursor-pointer material-symbols-rounded leading-none save-icon"
      [ngClass]="{'selected': form.valid}">{{submitIcon}}</span>
  </button>
</div>