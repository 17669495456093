import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import * as moment from 'moment';
import { Observable, concatMap, of } from 'rxjs';
import { ApiUrls } from 'src/app/util/common.enum';
import { environment } from 'src/environments/environment';
import { ImageFileModel } from '../model/image-file.model';

@Injectable()
export class ImageFileRepositoryService {

  constructor(private httpClient: HttpClient) { }

  getImages(locationId: string): Observable<ImageFileModel[]> {
    const url = locationId ? `?locationId=${locationId}` : ``;
    return this.httpClient.get<ImageFileModel[]>(`${environment.baseUrl}${ApiUrls.GetFileRepositoryImages}${url}`);
  }

  uploadFile(formData: any, token: string, path: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'X-Custom-Auth-Key': token,
        'X-File-Path': path,
      }),
      responseType: 'text' as const
    }
    return this.httpClient.put(`${environment.fileRepositoryUrl}`, formData, options);
  }

  getToken(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}${ApiUrls.GetFileUploadToken}`);
  }

  updateDb(data: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdateFileData}`, data);
  }

  getPublicToken(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}${ApiUrls.GetPublicFileUploadToken}`);
  }

  updatePublicFileDb(data: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdatePublicFileData}`, data);
  }

  uploadCompanyIcon(fileName: string) {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdateLogo}`, { logo: fileName });
  }

  uploadToServer(file: File, fileExt: string, companyId: string, locationId: string, fileName: string, isThumb: boolean, dbFlag: boolean) {
    const path = this.getPath(fileName, companyId, locationId, isThumb);
    const formData: FormData = new FormData();
    formData.append('file', file, fileName);
    const fileData = {
      locationId: locationId || '',
      fileName,
      fileType: file.type,
      fileSize: file.size,
      fileExt: fileExt
    }
    return this.getToken().
      pipe(
        concatMap((value: any) => this.uploadFile(file, value.token, path)),
        concatMap(() => {
          if (!isThumb && dbFlag) {
            return locationId ? this.updateDb(fileData) : this.uploadCompanyIcon(fileName);
          } else {
            return of(fileData);
          }
        })
      )
  }

  uploadToServerPublic(file: File, fileExt: string, companyId: string, locationId: string, fileName: string, isThumb: boolean, email: string) {
    const path = this.getPath(fileName, companyId, locationId, isThumb);
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const fileData = {
      companyId,
      locationId: locationId || '',
      fileName,
      fileType: file.type,
      fileSize: file.size,
      fileExt: fileExt,
      email: email || ''
    }
    return this.getPublicToken().
      pipe(
        concatMap((value: any) => this.uploadFile(file, value.token, path)),
        concatMap(() => {
          if (!isThumb) {
            return this.updatePublicFileDb(fileData);
          } else {
            return of(fileData);
          }
        })
      )
  }

  getPath(fileName: string, companyId: string, locationId: string, isThumb: boolean) {
    locationId = locationId ? `${locationId}/` : '';
    if (isThumb) {
      return `${companyId}/${locationId}thumb/${fileName}`;
    }
    return `${companyId}/${locationId}${fileName}`;
  }

}
